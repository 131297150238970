/* 
    Created on : 15-lug-2017, 10.49.57
    Author     : Fabio
*/

body, label, .checkbox label {
	font-weight: 300;
}

.error-page {
	background-color: @body-bg;
}

.main-header a {
    text-decoration:none;
}

.kaushian {
    font-family: 'Kaushan Script', cursive;
}
.text-shadow{
    text-shadow: 5px 5px 0px #282828;
}

#overlay {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: #FFF;
    opacity: 0.9;
    filter: alpha(opacity=90);
    z-index: 99999999999999999 !important;
    display: none;
}
.loading {
    position: fixed;
    top: 50%;
    left: 50%;
    margin: -28px 0 0 -103px;
    font-size: 30px;
    color: #605CA8;
    text-align: center;
}
#overlay .loading img {
    width: 64px;
    margin-bottom: 10px;
}

table td {
    vertical-align: middle !Important;
}

.imglogo{
    height: 25px;
    margin-bottom: 11px;
}
.imglogofix {
    margin-bottom: 6px !important
}

.info-box-custom {
    border: 1px solid #eee;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
    -mox-box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
}

/* Accordion */
#accordion .box-header {
    padding: 0 !Important;
}
#accordion .box-header h4 {
    width: 100% !Important;
}
#accordion .box-header h4 a {
    text-decoration: none !Important; 
    background: none !Important; 
    display: block !Important;
    padding: 10px !Important;
}
.image-header {
    padding: 0px;
}
.image-header h3 {
    margin: 0px;
}
.border-none {
    border: none !important;
}
.border-purple {
    border-color: #605CA8 !important;
}

.widget-user .widget-user-image a > img {
    width: 90px;
    height: auto;
    border: 3px solid #fff;
}

.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
}
.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 25px;
}
.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  font-size: 24px;
  line-height: 1.33;
  border-radius: 35px;
}

#circleWrapper {
  width: 42px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  min-height: 18px;
}

.circle {
  position: relative;
  height: 10px;
  width: 10px;
  margin-left: 2px;
  margin-right: 2px;
  top: 5px;
  background: #666;
  display: inline-block;
  border-radius: 50%;
}

.circle {
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

#circle1 {
  animation-name: circle1;
}

#circle2 {
  animation-name: circle2;
}

#circle3 {
  animation-name: circle3;
}

@-webkit-keyframes circle1 {
  0% {
    top: 5px;
  }
  15% {
    top: -5px;
  }
  25% {
    top: 5px;
  }
}

@-webkit-keyframes circle2 {
  10% {
    top: 5px;
  }
  25% {
    top: -5px;
  }
  35% {
    top: 5px;
  }
}

@-webkit-keyframes circle3 {
  15% {
    top: 5px;
  }
  30% {
    top: -5px;
  }
  40% {
    top: 5px;
  }
}

.adminbar {
    background: #333;
    padding: 5px 10px;
    color: #FFF;
}
.adminbar a {
    color: #FFF;
}
.bg-dark-grey {
    background: #333 !important;
    color: #FFF !Important;
}

.bg-dark-grey-blue {
    background: #34495E !important;
    color: #FFF !Important;
}